<div class="footer">
    Unauthorized attempts to sign into this<br/>system are prohibited by law.<br/>
    Copyright 2023 KIOS Inc. All rights reserved.
</div>

<style lang="scss">
  .footer {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 175%;
    text-align: center;
    color: var(--color-background-purple);
  }
</style>